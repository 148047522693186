import * as React from 'react';
import PropTypes from 'prop-types';
import {FabTo} from "./FabTo";
import {Table as MaterialTable, Box, Paper, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel, Toolbar, Typography} from "@material-ui/core";
import SearchBar from "material-ui-search-bar";
import {useEffect} from "react";

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead({order, orderBy, onRequestSort, columns}) {
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    const visuallyHidden = {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: '1px',
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        whiteSpace: 'nowrap',
        width: '1px',
    };

    return (
        <TableHead>
            <TableRow>
                {columns.map((column) => (
                    <TableCell
                        key={column.id}
                        align={column.align}
                        padding="normal"
                        sortDirection={orderBy === column.id ? order : false}
                    >
                        {column.sortable ? <TableSortLabel
                            active={orderBy === column.id}
                            direction={orderBy === column.id ? order : 'asc'}
                            onClick={createSortHandler(column.id)}
                        >
                            {column.label}
                            {orderBy === column.id ? (
                                <Box component="span" style={visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>
                            ) : null}
                        </TableSortLabel> : column.label}
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    columns: PropTypes.array.isRequired
};

const EnhancedTableToolbar = ({title, children}) => {
    return (
        <Toolbar>
            {title && <Typography
                style={{flexGrow: 1}}
                variant="h6"
                id="tableTitle"
                component="div"
            >
                {title}
            </Typography>}
            {children}
        </Toolbar>
    );
};

EnhancedTableToolbar.propTypes = {
    title: PropTypes.string
};

const Table = ({title, columns, rows, defaultRowsPerPage, defaultRowsPerPageOptions, withEmptyRows, fab}) => {
    const defaultOrderColumn = (
        columns.filter(column => column.sortable && column.default !== null)[0] ||
        columns.filter(column => column.sortable)[0]
    )

    const [order, setOrder] = React.useState(defaultOrderColumn.default || 'asc');
    const [orderBy, setOrderBy] = React.useState(defaultOrderColumn.id);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(defaultRowsPerPage);
    const [filteredRows, setFilteredRows] = React.useState([]);
    const [searched, setSearched] = React.useState('');

    useEffect(() => {
        setFilteredRows([...rows])
    }, [rows])

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const requestSearch = (searchedVal) => {
        setFilteredRows(rows.filter((row) => {
            return searchedVal.trim().toLowerCase().split(/\s+/).every((searchedWord) => {
                return columns.some(column => {
                    let searchValue

                    if (column.searchValue) {
                        searchValue = column.searchValue(row[column.id], row, column)
                    } else if (column.render) {
                        searchValue = column.render(row[column.id], row, column)
                    } else {
                        searchValue = row[column.id]
                    }

                    if (typeof searchValue !== 'string') {
                        return false
                    }

                    return searchValue.toLowerCase().includes(searchedWord)
                })
            })
        }));
        setPage(0);
    };

    const cancelSearch = () => {
        setSearched('');
        setFilteredRows([...rows]);
        setPage(0);
    };

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - filteredRows.length) : 0;

    return (
        <Box sx={{width: '100%'}}>
            <Paper sx={{width: '100%', mb: 2}} style={{position: 'relative'}}>
                {fab && <FabTo color="primary" to={fab.to} style={{position: 'absolute', right: '-28px', top: '-28px'}}>{fab.icon}</FabTo>}
                <EnhancedTableToolbar title={title}>
                    <div style={{marginRight: '15px', marginTop: '10px'}}>
                        <SearchBar
                            value={searched}
                            onChange={(searchVal) => requestSearch(searchVal)}
                            onCancelSearch={() => cancelSearch()}
                        />
                    </div>
                </EnhancedTableToolbar>
                <TableContainer>
                    <MaterialTable
                        sx={{minWidth: 750}}
                        aria-labelledby="tableTitle"
                        size={'medium'}
                    >
                        <EnhancedTableHead
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                            columns={columns}
                        />
                        <TableBody>
                            {stableSort(filteredRows, getComparator(order, orderBy))
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row, rowIndex) => {
                                    return (
                                        <TableRow
                                            hover
                                            tabIndex={-1}
                                            key={`row_${rowIndex}`}
                                        >
                                            {columns.map((column, columnIndex) => {
                                                return (
                                                    <TableCell
                                                        align={column.align}
                                                        key={`column_${rowIndex}_${columnIndex}`}
                                                    >
                                                        {
                                                            column.render ?
                                                                column.render(row[column.id], row, column) :
                                                                row[column.id]
                                                        }
                                                    </TableCell>
                                                )
                                            })}
                                        </TableRow>
                                    );
                                })}
                            {withEmptyRows && emptyRows > 0 && (
                                <TableRow
                                    style={{
                                        height: (53) * emptyRows,
                                    }}
                                >
                                    <TableCell colSpan={columns.length}/>
                                </TableRow>
                            )}
                        </TableBody>
                    </MaterialTable>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={defaultRowsPerPageOptions}
                    component="div"
                    count={filteredRows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
        </Box>
    );
}

Table.propTypes = {
    title: PropTypes.string,
    columns: PropTypes.array.isRequired,
    rows: PropTypes.array.isRequired,
    defaultRowsPerPage: PropTypes.number.isRequired,
    defaultRowsPerPageOptions: PropTypes.array.isRequired,
    withEmptyRows: PropTypes.bool,
    fab: PropTypes.object
};

export default Table
